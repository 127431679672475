import React from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Constants, EGender, ELanguage } from '@src/types/constants'
import { range } from 'lodash-es'
import { removeSinFormat, sinFormat } from '@src/services/Formatter'
import { PersonalInformationInput, PersonalInformationInputSchema } from '@src/types/CreditApplicationSchema'
import { ScrollableAlert } from '@src/components'
import { FORM_ID } from './StepperFormFooter'

type Props = {
  prequalificationData: PersonalInformationInput
  onPrequalificationUpdated: (data: PersonalInformationInput) => void
  nextStepAfter: (currentStep: string) => void
  currentStep: string
}

const fixNumberInTwoDigits = (num: number) => {
  return (num < 10 ? '0' : '') + num
}

const PersonalInformationForm = ({
  prequalificationData,
  onPrequalificationUpdated,
  nextStepAfter,
  currentStep,
}: Props) => {
  const { t, i18n } = useTranslation()

  const [birthDate, setBirthDate] = React.useState<{ [k: string]: number | undefined }>({
    year: undefined,
    month: undefined,
    day: undefined,
  })

  const [daysInMonth, setDaysInMonth] = React.useState(31)

  const validationSchema = PersonalInformationInputSchema
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    getValues,
    trigger,
  } = useForm<PersonalInformationInput>({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
    defaultValues: prequalificationData,
  })
  const watchBirthDate = watch('applicant.birthDate')

  React.useEffect(() => {
    const d = watchBirthDate?.split('-') ?? ['', '', '']
    if (watchBirthDate)
      setBirthDate({
        year: Number(d[0]),
        month: Number(d[1]),
        day: Number(d[2]),
      })
  }, [watchBirthDate])

  const handleDate = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setBirthDate((birth) => {
      const { value } = event.target
      switch (event.target.id) {
        case 'month':
          birth.month = +value
          break
        case 'year':
          birth.year = +value
          break
        default:
          birth.day = +value
      }

      if (birth.year !== undefined && birth.month !== undefined) {
        const days = new Date(+birth.year, birth.month, 0).getDate()
        setDaysInMonth(days)
        if (birth.day !== undefined && birth.day > days) {
          birth.day = undefined
        }
      }

      if (!Object.values(birth).includes(undefined)) {
        setValue(
          'applicant.birthDate',
          `${birth.year}-${fixNumberInTwoDigits(Number(birth.month))}-${fixNumberInTwoDigits(Number(birth.day))}`,
        )
      } else {
        setValue('applicant.birthDate', '')
      }

      return birth
    })
  }

  const currentDate = new Date()

  const minBirthDate = {
    year: currentDate.getFullYear() - 18,
    month: currentDate.getMonth() + 1,
    day: currentDate.getDate(),
  }

  const maxBirthDate = {
    year: currentDate.getFullYear() - 75,
    month: 1,
    day: 1,
  }

  const handleSinOnChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const sin = removeSinFormat(value)
    setValue('applicant.sin', sin)
    await trigger('applicant.sin')
  }

  const onSubmit: SubmitHandler<PersonalInformationInput> = (data) => {
    onPrequalificationUpdated(data)
    nextStepAfter(currentStep)
  }

  return (
    <section className="step-content">
      <ScrollableAlert
        showAlert={
          !!(
            errors.applicant?.birthDate &&
            errors.applicant.birthDate.type !== 'required' &&
            errors.applicant.birthDate.message
          )
        }
        type="error"
        message={errors.applicant?.birthDate?.message || ''}
      />
      <h3 className="form-question">{t('personalInformation.aboutYourself')}</h3>

      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <div className={`control-group ${errors.applicant?.languageId && 'error'}`}>
          <label htmlFor="radiobuttons">{t('personalInformation.preferredLanguage')}</label>
          <div className="radiobuttons-wrap">
            <div className="radio-wrap">
              <input
                type="radio"
                value={ELanguage.French}
                id="French"
                {...register('applicant.languageId')}
                defaultChecked={prequalificationData.applicant.languageId === ELanguage.French}
              />
              <label htmlFor="French">{t(`enum.ePreferredLanguage.${ELanguage.French}`)}</label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                value={ELanguage.English}
                id="English"
                {...register('applicant.languageId')}
                defaultChecked={prequalificationData.applicant.languageId === ELanguage.English}
              />
              <label htmlFor="English">{t(`enum.ePreferredLanguage.${ELanguage.English}`)}</label>
            </div>
          </div>
        </div>

        <div className={`control-group ${errors.applicant?.genderId && 'error'}`}>
          <label htmlFor="radiobuttons">{t('personalInformation.yourGender')}</label>
          <div className="radiobuttons-wrap">
            <div className="radio-wrap">
              <input
                type="radio"
                value={0}
                id="Male"
                {...register('applicant.genderId')}
                defaultChecked={prequalificationData.applicant.genderId === EGender.Male}
              />
              <label htmlFor="Male">{t('personalInformation.male')}</label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                value={1}
                id="Female"
                {...register('applicant.genderId')}
                defaultChecked={prequalificationData.applicant.genderId === EGender.Female}
              />
              <label htmlFor="Female">{t('personalInformation.female')}</label>
            </div>
            <div className="radio-wrap">
              <input
                type="radio"
                value={2}
                id="Other"
                {...register('applicant.genderId')}
                defaultChecked={prequalificationData.applicant.genderId === EGender.Other}
              />
              <label htmlFor="Other"> {t('personalInformation.preferNotSay')}</label>
            </div>
          </div>
        </div>

        <div className="control-group-wrap">
          <div className={`control-group third sm-full ${errors.applicant?.firstName && 'error'}`}>
            <label htmlFor="firstName">
              {t('personalInformation.yourFirstName')}
              <span className="form-hint">{t('personalInformation.nameHint')}</span>
            </label>
            <input type="text" id="firstName" {...register('applicant.firstName')} maxLength={50} />
          </div>

          <div className={`control-group third sm-full ${errors.applicant?.middleName && 'error'}`}>
            <label htmlFor="middleName">
              {t('personalInformation.yourMiddleName')}
              <span className="form-hint">{t('personalInformation.optional')}</span>
            </label>
            <input type="text" id="middleName" {...register('applicant.middleName')} maxLength={50} />
          </div>

          <div className={`control-group third sm-full ${errors.applicant?.lastName && 'error'}`}>
            <label htmlFor="lastName">
              {t('personalInformation.yourLastName')}
              <span className="form-hint">{t('personalInformation.nameHint')}</span>
            </label>
            <input type="text" id="lastName" {...register('applicant.lastName')} maxLength={50} />
          </div>
        </div>

        <div className="control-group">
          <label htmlFor="year">{t('personalInformation.yourBirthDate')}</label>
          <div className="date-wrap">
            <div className={`dropdown-wrap ${errors.applicant?.birthDate && 'error'}`}>
              <select id="year" name="date" onChange={handleDate} value={birthDate.year?.toString() ?? ''}>
                <option value="" disabled>
                  {t('personalInformation.birthYear')}
                </option>
                {range(minBirthDate.year, maxBirthDate.year).map((item: number) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
            <div className={`dropdown-wrap ${errors.applicant?.birthDate && 'error'}`}>
              <select
                id="month"
                name="date"
                onChange={handleDate}
                value={birthDate.month ? fixNumberInTwoDigits(birthDate.month) : ''}
              >
                <option value="" disabled>
                  {t('personalInformation.birthMonth')}
                </option>
                {Object.keys(Constants.monthNames).map((month) => (
                  <option value={fixNumberInTwoDigits(+month)} key={Constants.monthNames[Number(month)].nameEn}>
                    {i18n.language === 'en'
                      ? Constants.monthNames[Number(month)].nameEn
                      : Constants.monthNames[Number(month)].nameFr}
                  </option>
                ))}
              </select>
            </div>
            <div className={`dropdown-wrap ${errors.applicant?.birthDate && 'error'}`}>
              <select
                id="day"
                name="date"
                onChange={handleDate}
                value={birthDate.day ? fixNumberInTwoDigits(birthDate.day) : ''}
              >
                <option value="" disabled>
                  {t('personalInformation.birthDay')}
                </option>
                {range(1, daysInMonth + 1).map((item: number) => (
                  <option key={item} value={fixNumberInTwoDigits(item)}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className={`control-group  ${errors.applicant?.sin && 'error'}`}>
          <label htmlFor="insurance-number">
            {t('personalInformation.sin')}
            <span className="help-tip">
              <p>{t('personalInformation.optionalSin')}</p>
            </span>
          </label>
          <input
            id="insurance-number"
            placeholder="XXX-XXX-XXX"
            className="sin-input"
            type="text"
            inputMode="numeric"
            defaultValue={sinFormat(getValues('applicant.sin') as string)}
            onBlur={handleSinOnChange}
          />
        </div>
      </form>
    </section>
  )
}

export default PersonalInformationForm
