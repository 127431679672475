import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useTranslation } from 'react-i18next'
import { applicantFullName, hasSignedContract } from '@src/data/creditapp-selectors'
import CancelAppButton from '@src/components/CancelAppButton'
import BackButton from '@src/components/BackButton'
import { FormatCurrency } from '@src/services/Formatter'
import useTermPaymentsTable from '@src/containers/InitialApprobationPage/hooks'
import { useMemo } from 'react'

type Props = {
  creditApp: FilteredCreditApplication
}

const CreditSummaryPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const hasSignedCvt = hasSignedContract(creditApp)
  const [monthlyPaymentPerMonths, isLoadingTerms] = useTermPaymentsTable(creditApp)
  const minTermPayment = useMemo(
    () => (monthlyPaymentPerMonths ? monthlyPaymentPerMonths[monthlyPaymentPerMonths.length - 1] : null),
    [monthlyPaymentPerMonths],
  )
  const maxTermPayment = useMemo(
    () => (monthlyPaymentPerMonths ? monthlyPaymentPerMonths[0] : null),
    [monthlyPaymentPerMonths],
  )

  if (isLoadingTerms) return null
  return (
    <main className="form-section">
      <div className="credit-steps">
        <div className="applicationInfo-card paragraph">
          <h3>{t('common.appNumber', { number: creditApp.referenceNumber })}</h3>
          <div>
            <p className="applicationInfo-card-subtitle">
              <strong>{t('common.applicant')} : </strong>
              {applicantFullName(creditApp.applicant)}
            </p>
          </div>
          {!hasSignedCvt && (
            <div className="btn-group">
              <CancelAppButton creditAppId={creditApp.id} />
            </div>
          )}
        </div>
        <div className="applicationInfo-card paragraph">
          <h3>{t('loan.details')}</h3>
          <div>
            <p>
              <strong>{t('common.maxLoanAmount')} : </strong>
              {FormatCurrency(creditApp.finalDecision.maxAmountFinanced)}
            </p>
            <p>
              <strong>{t('common.loanAmount')} : </strong>
              {FormatCurrency(creditApp.worksheet?.amountRequested)}
            </p>
            <p>
              <strong>{t('common.monthlyPaymentMin')} : </strong>
              {t('loan.monthPeriodItem', {
                loanTerm: minTermPayment?.term,
                loanAmount: FormatCurrency(minTermPayment?.amountWithoutInsurance),
              })}
            </p>
            <p>
              <strong>{t('common.monthlyPaymentMax')} : </strong>
              {t('loan.monthPeriodItem', {
                loanTerm: maxTermPayment?.term,
                loanAmount: FormatCurrency(maxTermPayment?.amountWithoutInsurance),
              })}
            </p>
            <p>
              {t('common.monthlyAmountsInclude', {
                financeFee: creditApp?.normsDecision.lenderFeeRate,
                interestRate: creditApp?.finalDecision.interestRate,
              })}
            </p>
          </div>
        </div>{' '}
      </div>
      <BackButton />
    </main>
  )
}

export default CreditSummaryPage
