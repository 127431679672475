import { EDocumentStatus } from '@src/types'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { useTranslation } from 'react-i18next'
import useRequiredDocumentsWithStatus from '@src/data/creditapp-hooks'
import { manualUploadNotAllowedDocsTypes } from '@src/data/creditapp-selectors'
import RequiredDocumentTable from './RequiredDocumentTable'
import SectionTitle from './PageSectionTitle'

type Props = {
  creditApp: FilteredCreditApplication
}
const UploadFilesTable = ({ creditApp }: Props): JSX.Element => {
  const reqDocList = useRequiredDocumentsWithStatus(creditApp.requiredDocuments, creditApp.documents)
  const { t } = useTranslation()

  const applicantRequiredDocs = reqDocList.filter(
    (d) => !manualUploadNotAllowedDocsTypes.has(d.typeId) && d.status !== EDocumentStatus.Approved,
  )

  return (
    <div className="paragraph" id="paragraph-document">
      <SectionTitle title={t('common.documents')} />
      {applicantRequiredDocs.length > 0 && (
        <RequiredDocumentTable
          applicant={`${creditApp.applicant.firstName} ${creditApp.applicant.lastName}`}
          requiredDocuments={applicantRequiredDocs}
          creditApplicationId={creditApp.id}
        />
      )}
    </div>
  )
}

export default UploadFilesTable
