import { ErrorPage, Loader } from '@src/components'
import { FilteredCreditApplication } from '@src/api/types/FilteredCreditApplication'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { useGetIdentityVerificationSession } from '@src/api/identity-verification-session-api'
import { EMAIL, PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import VouchedComponent from './VouchedComponent'

type Props = {
  creditApp: FilteredCreditApplication
}

const VouchedPage = ({ creditApp }: Props) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const [session, isLoading] = useGetIdentityVerificationSession(id!)

  if (isLoading) return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} />
  if (creditApp.vouchedFailedCount > 1)
    return (
      <ErrorPage
        title={t('identification.failedNoRetryTitle')}
        description={
          <>
            {t('identification.failedNoRetryDescription')}
            <br />
            <br />
            <h4>{t('identification.needHelp')}</h4>
            <Trans
              i18nKey="common.contactUs"
              values={{
                phoneNumber: PHONE_NUMBER,
                email: EMAIL,
              }}
              components={{
                phone: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} />,
                email: (
                  <Link
                    to={`mailto:${EMAIL}?subject=${t('identification.needHelpSubject', { applicationNumber: creditApp.referenceNumber })}&body=${t('identification.needHelpBody', { applicationNumber: creditApp.referenceNumber })}`}
                    style={{ textWrap: 'nowrap' }}
                  />
                ),
              }}
            />
          </>
        }
        disableBackButton
      />
    )

  return <VouchedComponent id="vouched-element" session={session} creditApplication={creditApp} />
}

export default VouchedPage
