import { useGetCreditApplicationById } from '@src/api/credit-api'
import { getStringFromQueryString } from '@src/services/query-string'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ErrorPage, Loader } from '@src/components'
import { useTranslation } from 'react-i18next'
import { contractHasSigner, contractIsNotReady } from './ViewCreditApplication/HardHitApprovedResultPage/hooks'

const PROGRESSION_INTERVAL_STEP = 100 / 120 // 120 sec (2 minutes) / 100%

const ConsignoCallBack = () => {
  const [searchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [shouldPollCreditApp, setShouldPollCreditApp] = useState(true)
  const [creditApp, isFetchingCreditApp] = useGetCreditApplicationById(id!, shouldPollCreditApp)
  const [progress, setProgress] = useState<number>(0)

  const status = getStringFromQueryString(searchParams, 'status')

  useEffect(() => {
    if (!creditApp && !isFetchingCreditApp) navigate('', { replace: true })
    else if (!isFetchingCreditApp && creditApp && (contractIsNotReady(creditApp) || contractHasSigner(creditApp))) {
      navigate(`/creditapplication/${id}`, { replace: true })
    }
  }, [isFetchingCreditApp, setShouldPollCreditApp, creditApp, navigate, id, status])

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((p) => {
        if (p >= 100 - PROGRESSION_INTERVAL_STEP) clearInterval(interval)
        const np = p + PROGRESSION_INTERVAL_STEP
        if (np > 100) return 100
        return np
      })
    }, 1000) // 1 sec

    const timeoutPollCreditApp = setTimeout(() => {
      setShouldPollCreditApp(false)
      clearInterval(interval)
    }, 120000) // 2 minutes

    return () => {
      clearTimeout(timeoutPollCreditApp)
      clearInterval(interval)
    }
  }, [])

  if (!shouldPollCreditApp) return <ErrorPage description={t('contract.errorMessage')} />

  return <Loader title={t('common.withYouShortly')} description={t('common.pleaseWait')} progression={progress} />
}

export default ConsignoCallBack
