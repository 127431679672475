import { ChangeEventHandler, KeyboardEventHandler, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNeedHelp } from '@src/api/credit-api'
import { reportErrorToConsole } from '@src/services/error-logger'
import { Link } from 'react-router-dom'
import { PHONE_NUMBER, PHONE_NUMBER_LINK } from '@src/types'
import InputText from './InputText'
import ExpandableSection from './ExpandableSection'
import ScrollableAlert from './ScrollableAlert'

type Props = {
  creditApplicationId: string
  description: string
}

const NeedHelp = ({ creditApplicationId, description }: Props) => {
  const { t } = useTranslation()
  const [needHelp, pending, isSuccess, isError] = useNeedHelp()
  const [comment, setComment] = useState<string>('')

  const handleSubmit = () => {
    needHelp({
      creditApplicationId,
      description,
      comment: comment || null,
    }).catch(reportErrorToConsole)
    setComment('')
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === 'Enter') {
      // Intercept OnEnter event to prevent submitting parent form
      event.preventDefault()
      // Simulating submit
      ;(event.target as HTMLInputElement).blur()
      handleSubmit()
    }
  }

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setComment(e.target.value)
  }

  return (
    <div style={{ marginTop: '16px', marginBottom: '32px' }}>
      <ExpandableSection toggleText={t('help.toggle')}>
        <div>
          <div style={{ marginTop: '8px' }}>
            <Trans
              i18nKey="help.description"
              values={{
                phoneNumber: PHONE_NUMBER,
              }}
              components={{ a: <Link to={PHONE_NUMBER_LINK} style={{ textWrap: 'nowrap' }} /> }}
            />
          </div>
          <ScrollableAlert
            showAlert={isSuccess || isError}
            type={isSuccess ? 'success' : 'error'}
            message={isSuccess ? t('help.success') : t('common.unexpectedError')}
            style={{ marginBottom: 0, marginTop: '16px' }}
          />
          {!isSuccess && (
            <div style={{ display: 'flex', gap: '16px', alignItems: 'end' }}>
              <InputText
                id="help-input"
                style={{ flexGrow: 1 }}
                placeholder={t('help.label')}
                onKeyDown={handleKeyDown}
                value={comment}
                onChange={handleOnChange}
                disabled={pending}
              />
              <div className="control-group-end">
                <button
                  type="button"
                  className="btn btn-blue"
                  style={{ marginTop: 0, width: '100%' }}
                  onClick={handleSubmit}
                  disabled={pending}
                >
                  {t('help.submit')}
                </button>
              </div>
            </div>
          )}
        </div>
      </ExpandableSection>
    </div>
  )
}

export default NeedHelp
