import { memo } from 'react'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const { accessibilityPolicy, privacyPolicy } =
    resolvedLanguage === 'en'
      ? {
          accessibilityPolicy: 'https://ifinancecanada.com/accessible-customer-service-policy/',
          privacyPolicy: 'https://ifinancecanada.com/privacy-policy/',
        }
      : {
          accessibilityPolicy: 'https://ifinancecanada.com/fr/politique-acces-facile-de-service-a-la-clientele/',
          privacyPolicy: 'https://ifinancecanada.com/fr/politique-de-confidentialite/',
        }
  return (
    <footer className="footer">
      <div className="footer-content">
        <span className="copyright">{t('footer.copyRight')}</span>
        <div className="footer-links">
          <a href={accessibilityPolicy}>{t('footer.accessibilityPolicy')}</a>
          <a href={privacyPolicy}>{t('footer.privacyPolicy')}</a>
        </div>
      </div>
    </footer>
  )
}

export default memo(Footer)
