import { useParams } from 'react-router-dom'
import ContractWaitingLoader from './ContractWaitingLoader'
import ErrorPage from '../ErrorPage'

const ContractWaitingPage = () => {
  const { id } = useParams<{ id: string }>()

  if (!id) return <ErrorPage />

  return <ContractWaitingLoader creditAppId={id} />
}

export default ContractWaitingPage
