import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { reportErrorToConsole, reportErrorToServer } from '@src/services/error-logger'
import { SubmitDraftCreditApplicationDto, usePrequalify, useSubmitDraftCreditApplication } from '@src/api/credit-api'
import { FormatCurrency } from '@src/services/Formatter'
import AsyncActionButton from '@src/components/AsyncActionButton'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useAppStore } from '@src/data/AppContext'
import { AxiosError } from 'axios'
import { getRuntimeVariable } from '@src/services/utils'
import { AcceptTermsCheckbox } from '@src/components'
import ReviewApplicantInfo from './ReviewApplicantInfo'
import { Prequalification } from '../../../types/CreditApplicationSchema'

type Props = {
  gotoStep: (step: string) => void
  prequalification: Prequalification
}

const ReviewPrequalification = ({ prequalification, gotoStep }: Props) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [submitDraftCreditApplication, isSubmitting, isSuccess] = useSubmitDraftCreditApplication()
  const [prequalify] = usePrequalify()

  const appContext = useAppStore()

  const applicant = prequalification.applicant

  const buildDtoAndSubmitPrequal = async (evt: { preventDefault: () => void }) => {
    evt.preventDefault()

    try {
      if (!executeRecaptcha) {
        reportErrorToConsole('ReCAPTCHA non disponible.')
        return
      }

      const recaptchaToken = await executeRecaptcha('submit_postDraftCreditApplication')
      const dto: SubmitDraftCreditApplicationDto = {
        recaptchaToken,
        prequalification: {
          ...prequalification,
          origination: appContext.origination,
        },
      }
      const creditApp = await submitDraftCreditApplication(dto)

      if (getRuntimeVariable<boolean>(import.meta.env.VITE_USE_VOUCHED)) {
        navigate(`/creditApplication/${creditApp.id}/vouched`, { replace: true })
      } else {
        await prequalify({ creditApplicationId: creditApp.id, vouchedJobToken: 'dev' })
        navigate(`/creditApplication/${creditApp.id}`, { replace: true })
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 424) {
        navigate('/recaptcha-validation-failed')
      } else {
        reportErrorToServer(error as Error)
      }
    }
  }
  return (
    <section className="step-content">
      <h3 className="form-question">{t('review.title')}</h3>

      <div className="paragraph">
        <p>{t('review.description')}</p>
      </div>

      <form onSubmit={buildDtoAndSubmitPrequal}>
        <div className="review-wrap">
          <div className="review-item">
            <h1 className="h3">{t('review.desiredLoanAmount')}</h1>
            <div className="box">
              <div className="box-content">
                <p>
                  <strong>{t('loanAmount.amount')} : </strong>
                  <span>{FormatCurrency(prequalification.requestedLoanAmount)}</span>
                </p>
              </div>
              <button type="button" className="btn-action btn-simple" onClick={() => gotoStep('loan-amount')}>
                {t('review.modify')}
              </button>
            </div>
          </div>
          <ReviewApplicantInfo applicant={applicant} gotoStep={gotoStep} />
        </div>
        <footer className="review-footer">
          <h3>
            <Trans t={t} i18nKey="confirmation.consentTitle" />
          </h3>

          <div className="paragraph">
            <AcceptTermsCheckbox
              msgOnInvalid={t('common.acceptConditions')}
              id="acceptConditions"
              termId={t('confirmation.consent')}
            />

            <AcceptTermsCheckbox msgOnInvalid={t('common.acceptConditions')} id="acceptAuthorise">
              {t('confirmation.authorization')}
              <a href={t('common.policyLink')} target="_blank" rel="noreferrer" className="blue-link">
                {' '}
                {t('contact.linkText')} <i className="fa-solid fa-arrow-up-right-from-square" />
              </a>
              {t('confirmation.authorizationEnd')}
            </AcceptTermsCheckbox>
            <AcceptTermsCheckbox msgOnInvalid={t('common.acceptConditions')} id="acceptVouched">
              {t('confirmation.vouchedConsent')}
              <a href="/digital-verification-policy" target="_blank" rel="noreferrer" className="blue-link">
                {' '}
                {t('confirmation.vouchedPolicy')} <i className="fa-solid fa-arrow-up-right-from-square" />
              </a>
              {', '}
              {t('confirmation.confirm')}
            </AcceptTermsCheckbox>
          </div>

          <AsyncActionButton
            type="submit"
            className="btn btn-blue"
            disabled={isSubmitting || isSuccess}
            isPending={isSubmitting}
            primary
          >
            {t('review.getQuote')}
          </AsyncActionButton>
        </footer>
      </form>
    </section>
  )
}

export default ReviewPrequalification
