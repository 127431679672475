import { useForm, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Trans, useTranslation } from 'react-i18next'
import InputText from '@src/components/InputText'
import { Constants } from '@src/types'
import { FormatCurrencyNoDecimals } from '@src/services/Formatter'
import { FORM_ID } from './StepperFormFooter'
import { LoanAmountInput, LoanAmountInputSchema } from '../../../types/CreditApplicationSchema'

type Props = {
  prequalificationData: LoanAmountInput
  onPrequalificationUpdated: (data: LoanAmountInput) => void
  nextStepAfter: (currentStep: string) => void
  currentStep: string
}

const LoanAmountForm = ({ prequalificationData, onPrequalificationUpdated, nextStepAfter, currentStep }: Props) => {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoanAmountInput>({
    mode: 'onBlur',
    resolver: yupResolver(LoanAmountInputSchema),
    defaultValues: prequalificationData,
  })

  const onSubmit: SubmitHandler<LoanAmountInput> = (data) => {
    onPrequalificationUpdated(data)
    nextStepAfter(currentStep)
  }

  return (
    <section className="step-content">
      <h3 className="form-question">{t('loanAmount.desiredAmount')}</h3>
      <div className="paragraph">
        <p>
          <Trans
            t={t}
            i18nKey="loanAmount.amountThresholds"
            values={{
              minLoanAmount: FormatCurrencyNoDecimals(Constants.MinLoanAmount),
              maxLoanAmount: FormatCurrencyNoDecimals(Constants.MaxLoanAmount),
            }}
          />
        </p>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
        <InputText
          type="number"
          inputMode="numeric"
          error={errors.requestedLoanAmount}
          label={t('loanAmount.amount')}
          wrapStyle={resolvedLanguage === 'en' ? 'number-wrap startwith' : 'number-wrap endwith'}
          {...register('requestedLoanAmount')}
        />
      </form>
    </section>
  )
}

export default LoanAmountForm
