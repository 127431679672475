import BackButton from '@src/components/BackButton'
import SectionTitle from '@src/components/PageSectionTitle'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
  title?: string
  description?: ReactNode
  backhref?: string
  disableBackButton?: boolean
}

const ErrorPage = ({ title, description, backhref, disableBackButton }: Props) => {
  const { t } = useTranslation()
  return (
    <main className="step-0">
      <SectionTitle title={title ?? t('common.unexpectedError')} />

      <h1>{t('common.oopsTitle')}</h1>
      <div className="paragraph">{description ?? t('common.notFound')}</div>

      {!disableBackButton && <BackButton href={backhref ?? '/'} />}
    </main>
  )
}

export default ErrorPage
